<template>
  <div>
    <div
      class="bg-white rounded-3xl flex justify-between p-4 mx-10 my-3 border border-gray-100 shadow-lg"
    >
      <h3 :class="{ 'text-rose': isLtr }" class="font-bold text-base">
        {{ $t('report_patient') }}
        <span class="text-rose">{{ $t('report_environment') }}</span>
      </h3>
      <BackFa :text="$t('back')" />
    </div>
    <div :class="{ rtl: isLtr }" class="flex justify-between mx-10 mb-10">
      <router-link :to="{ name: 'BedRoom' }">
        <img width="50" :src="require(`../assets/images/${img2}.png`)" alt="" />
      </router-link>
      <div class="mr-10">
        <div
          :class="{ 'text-white': checkBox, 'bg-blue-400': checkBox }"
          class="bg-white w-28 py-2 border border-gray-100 rounded-lg shadow-xl"
        >
          <div class="flex justify-center items-center">
            <label class="switch">
              <input @click="checkBox = !checkBox" type="checkbox" />
              <span class="slider round"></span>
            </label>
            <span class="font-bold mr-3" v-if="!checkBox">off</span>
            <span class="font-bold mr-3" v-else>on</span>
          </div>
          <div class="flex justify-center mt-10">
            <span
              :class="{ 'text-white': checkBox }"
              class="text-rose text-sm font-bold"
              >{{ $t('report_') }}</span
            >
            <i class="-mt-2 fas fa-file-spreadsheet text-3xl mr-4"></i>
          </div>
          <div class="font-bold text-xs mt-1 text-center">DOCUMENTS</div>
        </div>
        <div
          :class="{ 'text-white': checkBox2, 'bg-blue-400': checkBox2 }"
          class="mt-7 mb-10 bg-white w-28 py-2 border border-gray-100 rounded-lg shadow-xl"
        >
          <div class="flex justify-center items-center">
            <label class="switch">
              <input @click="checkBox2 = !checkBox2" type="checkbox" />
              <span class="slider round"></span>
            </label>
            <span class="font-bold mr-3" v-if="!checkBox2">off</span>
            <span class="font-bold mr-3" v-else>on</span>
          </div>
          <div class="flex justify-center mt-10">
            <span
              :class="{ 'text-white': checkBox2 }"
              class="text-rose text-sm font-bold"
              >{{ $t('camera') }}</span
            >
            <i class="-mt-2 fas fa-video text-3xl mr-4"></i>
          </div>
          <div class="font-bold text-xs mt-1 text-center">LIVE CAMERA</div>
        </div>
        <div
          @click="$router.push({ name: 'Information' })"
          class="cursor-pointer h-20 w-20 pt-2 mt-5 mr-4 bg-purple-300 rounded-full"
        >
          <h3
            class="break-words text-center pt-2 mx-auto font-black border border-white text-white rounded-full h-16 w-16"
          >
            {{ $t('health_record') }}
          </h3>
        </div>
      </div>
      <!-- 2 -->
      <div class="mr-10 text-center">
        <div
          class="flex justify-between items-center px-4 w-60 h-12 border border-gray-100 rounded-xl shadow-md"
        >
          <div class="w-28 pt-2">
            <div class="flex justify-center -mb-2">
              <h3 class="font-black ml-1">C</h3>

              <h3 class="font-black">23</h3>
            </div>
            <span class="text-xs text-gray-600">Température</span>
          </div>
          <div class="h-8 w-8 rounded-full bg-purple-400 text-center">
            <i class="fal fa-long-arrow-up text-white mt-2"></i>
          </div>
        </div>
        <div
          class="flex justify-between items-center px-4 w-60 h-12 border border-gray-100 rounded-xl shadow-md"
        >
          <div class="w-28 pt-2">
            <div class="flex justify-center -mb-2">
              <h3 class="font-black ml-1">%</h3>
              <h3 class="font-black">45</h3>
            </div>
            <span class="text-xs text-gray-600">{{ $t('humidity') }}</span>
          </div>
          <div class="h-8 w-8 rounded-full bg-cyan-500 text-center">
            <i class="fal fa-long-arrow-left text-white mt-2"></i>
          </div>
        </div>
        <div
          class="flex justify-between items-center px-4 w-60 h-12 border border-gray-100 rounded-xl shadow-md"
        >
          <div class="w-28 pt-2">
            <h3 class="font-black ml-1 -mb-2">GOOD</h3>
            <span class="text-xs text-gray-600">{{ $t('air_qualityu') }}</span>
          </div>
          <div class="h-8 w-8 rounded-full bg-purple-400 text-center">
            <i class="fal fa-long-arrow-left text-white mt-2"></i>
          </div>
        </div>
        <div
          class="flex justify-between items-center px-4 w-60 h-12 border border-gray-100 rounded-xl shadow-md"
        >
          <div class="w-28 pt-2">
            <h3 class="font-black ml-1 -mb-2">CLEAR</h3>
            <span class="text-xs text-gray-600">{{ $t('fine_particle') }}</span>
          </div>
          <div class="h-8 w-8 rounded-full bg-cyan-500 text-center">
            <i class="fal fa-long-arrow-left text-white mt-2"></i>
          </div>
        </div>
        <div
          class="flex justify-between items-center px-4 w-60 h-12 border border-gray-100 rounded-xl shadow-md"
        >
          <div class="w-28 pt-2">
            <h3 class="font-black ml-1 -mb-2">2</h3>
            <span class="text-xs text-gray-600">CO2</span>
          </div>
          <div class="h-8 w-8 rounded-full bg-purple-400 text-center">
            <i
              style="transform: rotate(320deg)"
              class="fal fa-long-arrow-left rotate-icon text-white mt-2"
            ></i>
          </div>
        </div>
        <div
          class="flex justify-between items-center px-4 w-60 h-12 border border-gray-100 rounded-xl shadow-md"
        >
          <div class="w-28 pt-2">
            <h3 class="font-black ml-1 -mb-2">NONE</h3>
            <span class="text-xs text-gray-600">{{ $t('motion') }}</span>
          </div>
          <div class="h-8 w-8 rounded-full bg-cyan-500 text-center">
            <i
              style="transform: rotate(50deg)"
              class="fal fa-long-arrow-left rotate-icon text-white mt-2"
            ></i>
          </div>
        </div>
        <div
          class="flex justify-between items-center px-4 w-60 h-12 border border-gray-100 rounded-xl shadow-md"
        >
          <div class="w-28 pt-2">
            <h3 class="font-black ml-1 -mb-2">CLEAR</h3>
            <span class="text-xs text-gray-600">{{ $t('sound_noises') }}</span>
          </div>
          <div class="h-8 w-8 rounded-full bg-purple-400 text-center">
            <i class="fal fa-long-arrow-left rotate-icon text-white mt-2"></i>
          </div>
        </div>
        <div
          class="flex justify-between items-center px-4 w-60 h-12 border border-gray-100 rounded-xl shadow-md"
        >
          <div class="w-28 pt-2">
            <h3 class="font-black ml-1 -mb-2">GOOD</h3>
            <span class="text-xs text-gray-600">{{ $t('ambient') }} </span>
          </div>
          <div class="h-8 w-8 rounded-full bg-green-400">
            <i
              style="transform: rotate(50deg)"
              class="fal fa-long-arrow-left rotate-icon text-white mt-2"
            ></i>
          </div>
        </div>
      </div>
      <!--  -->
      <img
        v-if="!checkBox2"
        class="mr-10"
        width="400"
        :src="require(`../assets/images/${img}.png`)"
        alt=""
      />
      <img
        v-else
        class="mr-10"
        width="400"
        src="../assets/images/live.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import BackFa from '../components/back/BackFa.vue'

export default {
  name: 'LivingRoom',
  components: { BackFa },
  computed: {
    img() {
      return this.isRtl ? 'room' : 'room2-fr'
    },
    img2() {
      return this.isRtl ? 'bedroom' : 'bedroom-fr'
    },
  },
  data() {
    return {
      checkBox: false,
      checkBox2: false,
    }
  },
}
</script>

<style>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(243, 241, 241);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: rgb(240, 74, 74);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ccc;
}
input:checked + .slider:before {
  background-color: rgb(89, 104, 247);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(28px);
  -ms-transform: translateX(28px);
  transform: translateX(28px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
